<template>
  <fw-panel title="Challonge" :featured="featured" boxed :title-size="titleSize">
    <iframe
      v-if="challonge?.url"
      class="overflow-hidden rounded-2xl bg-challonge"
      :src="`${challonge?.url}/module?theme=2&multiplier=1.1`"
      width="100%"
      height="500"
      frameborder="0"
      scrolling="auto"
      allowtransparency="true"
    ></iframe>

    <div v-else class="bg-gray-300 rounded-2xl px-7 py-28 text-center text-gray-700">
      <div class="text-xl font-bold">Rondas deste torneio</div>
      Este torneio ainda não começou.
    </div>
  </fw-panel>
</template>

<script>
//  For more options see:
//  https://challonge.com/pt/module/instructions
export default {
  props: {
    challonge: {
      type: Object,
      default: () => {}
    },
    featured: {
      type: Boolean,
      default: true
    },
    titleSize: {
      type: String
    }
  }
}
</script>
