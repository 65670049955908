<template>
  <fw-panel :title="'Equipas em competição'" featured>
    <template v-if="isTournament" #after-heading
      ><span class="text-xl font-bold text-gray-400">neste torneio</span></template
    >
    <div
      v-if="loading"
      class="grid animate-pulse p-5 gap-5 grid-cols-2 sm:grid-cols-4 md:grid-cols-6 bg-white rounded-md"
    >
      <div class="rounded-lg bg-gray-200 h-48"></div>
      <div class="rounded-lg bg-gray-200 h-48"></div>
      <div class="rounded-lg bg-gray-200 h-48"></div>
      <div class="rounded-lg bg-gray-200 h-48"></div>
      <div class="rounded-lg bg-gray-200 h-48"></div>
    </div>
    <PanelEmptyTeams v-else-if="teams.length == 0" :personal="false" />
    <div v-else class="grid p-5 gap-2 grid-cols-2 sm:grid-cols-4 md:grid-cols-6 bg-white rounded-md">
      <CardTeam v-for="tm in teams" :key="tm.key" :team="tm" class="overflow-visible" />
    </div>
  </fw-panel>
</template>

<script>
import CardTeam from '@/components/cards/CardTeam'
import PanelEmptyTeams from '@/components/panels/PanelEmptyTeams.vue'

export default {
  components: {
    CardTeam,
    PanelEmptyTeams
  },
  props: {
    teams: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isTournament: {
      type: Boolean,
      default: false
    }
  }
}
</script>
